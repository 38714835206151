import React from "react";

type Props = {
    showGoTop: string,
    scrollUp: () => void
}

const GoTop = (props: Props) => {
  return (
    <>
      <div className={props.showGoTop} onClick={props.scrollUp}>
        <a href="#" id="toTop" className={props.showGoTop}><span id="toTopHover"></span>To Top</a>
      </div>
    </>
  );
};

export default GoTop;