import React, { useState, useEffect, useRef }  from "react";
import DocumentMeta from "react-document-meta";
import ImageList from "../components/ImageList";
import GoTop from "../components/GoTop";
import "../assets/css/animations.css";
import "../assets/css/shortcodes.css";
import "../assets/css/style.css";
import "../assets/css/base.css";
import "../assets/css/grid.css";
import "../assets/css/layout.css";
import "../assets/css/portfolio.css";
import "../assets/css/font-awesome.min.css";
import "../assets/css/responsive.css";
import "../assets/css/custom.css";

const HomePage = () => {
  const refScrollUp = useRef<null | HTMLDivElement>(null);
  const [scrollPosition, setSrollPosition] = useState(0);
  const [showGoTop, setshowGoTop] = useState("goTopHidden");
  const [menuCLass, setMenuCLass] = useState("menu");

  const meta = {
    title: "Ảnh viện áo cưới tại hà nội - K'lewedding Studio",
    description:
      "Ảnh viện áo cưới uy tín tại Hà Nội và chất lượng sẽ đem đến cho bạn những bộ ảnh cưới đẹp nhất. Nhưng trong thực tế việc lựa chọn được một studio chụp ảnh đẹp không phải là điều dễ dàng. Hãy đến K'lewedding Studio để trải nghiệm dịch vụ.",
    meta: {
      property: {
        "og:title": "Ảnh viện áo cưới tại hà nội - K'lewedding Studio",
        "og:url": "https://klewedding.com.vn/",
        "og:image": "https://klewedding.com.vn/logo512.png",
        "og:description":
          "Ảnh viện áo cưới uy tín tại Hà Nội và chất lượng sẽ đem đến cho bạn những bộ ảnh cưới đẹp nhất. Nhưng trong thực tế việc lựa chọn được một studio chụp ảnh đẹp không phải là điều dễ dàng. Hãy đến K'lewedding Studio để trải nghiệm dịch vụ.",

        "twitter:card": "summary_large_image",
        "twitter:title": "Ảnh viện áo cưới tại hà nội - K'lewedding Studio",
        "twitter:description":
          "Ảnh viện áo cưới uy tín tại Hà Nội và chất lượng sẽ đem đến cho bạn những bộ ảnh cưới đẹp nhất. Nhưng trong thực tế việc lựa chọn được một studio chụp ảnh đẹp không phải là điều dễ dàng. Hãy đến K'lewedding Studio để trải nghiệm dịch vụ.",
        "twitter:image": "https://klewedding.com.vn/logo512.png",
      },
    },
  };

  const handleVisibleButton = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);

    if (scrollPosition > 50) {
      return setshowGoTop("goTop");
    } else if (scrollPosition < 50) {
      return setshowGoTop("goTopHidden");
    }
  };

  const handleScrollUp = () => {
    if (refScrollUp !== null && refScrollUp.current !== null) {
      refScrollUp.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showMenu = (event: any) => {
    // event.preventDefault();
		
		// var $menu = $("nav#main-menu").find("ul.menu:first");
		// $menu.slideToggle(function(){
		// 	$menu.css('overflow' , 'visible');
		// 	$menu.toggleClass('menu-toggle-open');
		// });
    if (menuCLass == 'menu') {
      setMenuCLass('menu menu-toggle-open');
    } else {
      setMenuCLass('menu');
    }
    
			
  }

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  });

  return (
    <DocumentMeta {...meta}>
      <div ref={refScrollUp}> </div>
      <div className="home page-template-default page page-id-4 wp-embed-responsive layout-boxed fullwidth-header header-align-center fullwidth-menu-header standard-header woo-type1 wpb-js-composer js-comp-ver-6.4.2 vc_responsive">
        {/* **Wrapper** */}
        <div className="wrapper">
          <div className="inner-wrapper">
            {/* **Header Wrapper** */}
            <div id="header-wrapper" className="">
              {/* **Header** */}
              <header id="header">
                {/* **Main Header Wrapper** */}
                <div id="main-header-wrapper" className="main-header-wrapper">
                  <div className="container">
                    {/* **Main Header** */}
                    <div className="main-header">
                      <div className="header-left">K'lewedding</div>
                      <div id="logo">
                        <a
                          href="/"
                          title="klewedding"
                        >
                          <img
                            className="normal_logo"
                            src="/image/logo-new.jpg"
                            alt="klewedding studio makeup bridal"
                            title="klewedding studio makeup bridal"
                            width="200px"
                          />
                        </a>
                      </div>
                      <div className="header-right">Photos by: Khanh Tran</div>
                      <h1 className="seo-title">
                        Dịch vụ chụp ảnh cưới chất lượng, giá rẻ tại Hà Nội
                      </h1>
                      {/* <div
                        id="menu-wrapper-sticky-wrapper"
                        className="sticky-wrapper"
                        style={{ height: 70 }}
                      >
                        <div
                          id="menu-wrapper"
                          className="menu-wrapper "
                        >
                          <div className="dt-menu-toggle" id="dt-menu-toggle" onClick={showMenu}>
                            Menu <span className="dt-menu-toggle-icon" />
                          </div>
                          <nav
                            id="main-menu"
                            className="menu-header-menu-container"
                          >
                            <ul className={menuCLass} style={{}}>
                              <li
                                id="menu-item-4564"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-4 current_page_item menu-item-depth-0 menu-item-simple-parent "
                              >
                                <a href="#">
                                  Dịch vụ chụp ảnh cưới chất lượng, giá rẻ tại hà nội
                                </a>
                              </li>
                              <li
                                id="menu-item-4631"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0 menu-item-simple-parent "
                              >
                                <a href="#">
                                  About Us
                                </a>
                              </li>
                              <li
                                id="menu-item-4630"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0 menu-item-simple-parent "
                              >
                                <a href="#">
                                  Contact
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* **Main Header** */}
              </header>
              {/* **Header - End** */}
            </div>
            {/* **Header Wrapper - End** */}
            {/* **Main** */}
            <div id="main">
              {/* ** Container ** */}
              <div className="container">
                <section id="primary" className="content-full-width">
                  {/* #post-4 */}
                  <div
                    id="post-4"
                    className="post-4 page type-page status-publish hentry"
                  >
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1497616509790">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                          <div className="wpb_wrapper">
                            <div className="dt-sc-portfolio-container-wrapper">
                              <div
                                className="dt-sc-portfolio-container with-space"
                                data-column={3}
                              >
                                <div className="grid-sizer    dt-sc-one-third" />

                                <ImageList album="album01" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1499076717257">
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner ">
                          <div className="wpb_wrapper">
                            <div className="dt-sc-contact-info type3 ">
                              <h6>Photography</h6>
                              <span className="fa fa-camera"> </span>
                              <p>Khanh Tran</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner ">
                          <div className="wpb_wrapper">
                            <div className="dt-sc-contact-info type3 pattern-bg">
                              <h6>Hotline @</h6>
                              <span className="fa fa-phone"> </span>
                              <p>
                                <a className="hotline" href="tel:0354488589">035 44 88 589</a>
                                <br />
                                <a className="hotline" href="tel:0365256689">0365 256 689</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner ">
                          <div className="wpb_wrapper">
                            <div className="dt-sc-contact-info type3 ">
                              <h6>Địa chỉ</h6>
                              <span className="fa fa-map-marker"> </span>
                              <p>Lạc Thị, Ngọc Hồi, Thanh Trì, Hà Nội</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row wpb_row vc_row-fluid">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                          <div className="wpb_wrapper">
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <div
                                  id="sb_instagram"
                                  className="sbi sbi_mob_col_1 sbi_tab_col_2 sbi_col_7 sbi_width_resp sbi_medium"
                                  style={{ paddingBottom: 10, width: "100%" }}
                                  data-feedid="sbi_17841406085826537#7"
                                  data-res="medium"
                                  data-cols={7}
                                  data-colsmobile={1}
                                  data-colstablet={2}
                                  data-num={7}
                                  data-nummobile=""
                                  data-shortcode-atts='{"showbutton":"false","showfollow":"false","cols":"7","num":"7"}'
                                  data-postid={4}
                                  data-locatornonce="d5c3fb4357"
                                  data-sbi-flags="favorLocal"
                                  data-sbi-index={1}
                                >
                                  <div
                                    id="sbi_images"
                                    style={{ padding: 5 }}
                                  ></div>
                                  <div id="sbi_load"></div>
                                  <span
                                    className="sbi_resized_image_data"
                                    data-feed-id="sbi_17841406085826537#7"
                                    data-resized="[]"
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* #post-4 */}
                </section>
                {/* **Primary - End** */}
              </div>
              {/* **Container - End** */}
            </div>
            {/* **Main - End** */}
          </div>
          {/* **Inner Wrapper - End** */}
        </div>
        {/* **Wrapper - End** */}
      </div>
      <p style={{textAlign: 'center', marginBottom: '50px'}}>K'lewedding © 2022</p>
      <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
    </DocumentMeta>
  );
};

export default HomePage;
